import { persistanceCacheKey } from 'recoil/persistanceCacheEffect';

export function watchLocalStorageData(
  setState: React.Dispatch<React.SetStateAction<unknown>>,
  storageLookup = 'routePlannerAtom__"route_planner"'
) {
  const listener = (e) => {
    if (e.key !== persistanceCacheKey) return;
    const storageAtom = JSON.parse(window.localStorage.getItem(persistanceCacheKey))?.[
      storageLookup
    ];

    if (storageAtom) {
      setState(storageAtom);
    }
  };

  window.addEventListener('storage', listener);

  return () => {
    window.removeEventListener('storage', listener);
  };
}
