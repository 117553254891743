import { atomFamily } from 'recoil';

export type DispatchAtom = {
  hoveredTripResultId?: string;
  tripResultId?: string;
  tripLegId?: string;
  trackingId?: string;
  outOfBounds?: boolean;
};

export const dispatchAtom = atomFamily<DispatchAtom, string>({
  key: 'dispatchAtom',
  default: {
    tripResultId: null,
    hoveredTripResultId: null,
    tripLegId: null,
    trackingId: null,
    outOfBounds: false
  }
});
