import { postTripOnDashboard } from '@truckmap/common';
import { useDispatchPathBoolean } from 'hooks/useDispatch/useDispatchPathBoolean';
import { produce } from 'immer';
import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { dispatchAtom } from 'recoil/dispatch/dispatchAtom';

export const useDispatchResetTrip = (mapController) => {
  const searchParams = useSearchParams();
  const [, setDispatch] = useRecoilState(dispatchAtom(mapController));
  const dashboardUserId = searchParams.get('dashboard_user_id');
  const dashboardToken = searchParams.get('dashboard_token');
  const isDispatch = useDispatchPathBoolean();

  const resetDispatchTrip = useCallback(async () => {
    if (!isDispatch || !dashboardUserId || !dashboardToken) return;
    try {
      await postTripOnDashboard({
        tripId: null,
        trackingId: null,
        dashboardUserId,
        dashboardToken
      });

      setDispatch(
        produce((draft) => {
          draft.trackingId = null;
        })
      );

      return;
    } catch (e) {
      console.error(e);
    }
  }, [dashboardUserId, dashboardToken, isDispatch]);

  return {
    resetDispatchTrip,
    isDispatch
  };
};
