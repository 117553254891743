import { useGetMapInstance } from 'hooks/useMap/useMapGL/MapGLContext';
import { removeMarkers } from 'lib/map/handleTripMarkers';
import { MapControllers } from 'models/MapControllers';
import { useCallback } from 'react';

export function useMapGLTripMarkerHandlers(controllerId: MapControllers) {
  const getMapInstance = useGetMapInstance(controllerId);
  const removeMarkersHandler = useCallback(() => {
    const mapInstance = getMapInstance();
    if (mapInstance?.markersCache) {
      removeMarkers(mapInstance.markersCache);
    }
  }, [getMapInstance]);

  return { removeMarkersHandler };
}
