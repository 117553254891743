// map items use blue
import { ITrip } from '@truckmap/common';
import { activeDirectionsAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { TruckMapGL, useMapGLController } from 'hooks/useMap/useMapGL/MapGLContext';
import { useMapSourceEffect } from 'hooks/useMap/useMapSource/useMapSourceEffect';
import { RoutePlannerMapSources } from 'hooks/useMap/useRoutePlannerMap';
import {
  generateSourcesWithAlternateRoutes,
  getAllTripResults,
  handleSources
} from 'lib/routeUtils';
import { GeoJSONSource } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { routePlannerAtom as routePlannerAtomFamily } from 'recoil/routePlanner';

export function resetMapRoute(mapInstance: TruckMapGL) {
  (mapInstance?.getSource(RoutePlannerMapSources.ROUTE) as GeoJSONSource)?.setData({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: null
      }
    ]
  });
}

export const useMapGLTrip = (trip: ITrip, controllerId: MapControllers) => {
  const mapInstance = useMapGLController(controllerId);
  const positions = getAllTripResults(trip);
  const activeDirectionValue = useRecoilValue(activeDirectionsAtom(controllerId));

  const [routePlannerAtom, setRoutePlannerAtom] = useRecoilState(
    routePlannerAtomFamily(controllerId)
  );

  useEffect(() => {
    if (!mapInstance) return;

    handleSources({
      names: [RoutePlannerMapSources.ROUTE],
      sources: [
        () =>
          generateSourcesWithAlternateRoutes({
            tripId: trip.id,
            mapInstance,
            mapAtom: routePlannerAtom,
            setMapAtom: setRoutePlannerAtom,
            positions,
            activeDirectionValue,
            zoom: mapInstance.getZoom()
          })
      ],
      controllerId,
      reverse: true
    });
  }, [
    trip,
    mapInstance?.getZoom(),
    routePlannerAtom.hoveredTripResultId,
    routePlannerAtom.tripResultId,
    activeDirectionValue
  ]);

  useMapSourceEffect(() => {
    if (!mapInstance) return;

    handleSources({
      names: [RoutePlannerMapSources.ROUTE],
      sources: [
        () =>
          generateSourcesWithAlternateRoutes({
            tripId: trip.id,
            mapInstance,
            mapAtom: routePlannerAtom,
            setMapAtom: setRoutePlannerAtom,
            positions,
            activeDirectionValue
          })
      ],
      controllerId,
      reverse: true
    });
  }, controllerId);

  return trip;
};
