import { arrayMove } from '@dnd-kit/sortable';
import { IPlace, SearchResult } from '@truckmap/common';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { useDispatchResetTrip } from 'hooks/useDispatch/useDispatchResetTrip';
import { resetDirectionPoint } from 'hooks/useMap/useMapDirectionFocus';
import { useGetMapInstance } from 'hooks/useMap/useMapGL/MapGLContext';
import { resetMapRoute } from 'hooks/useMap/useMapGL/useMapGLTrip';
import { useMapGLTripMarkerHandlers } from 'hooks/useMap/useMapGL/useMapGLTripMarkerHandlers';
import { produce } from 'immer';
import { pingEvent } from 'lib/analytics/event';
import { watchLocalStorageData } from 'lib/auth/watchLocalStorageData';
import { removeTripCookie } from 'lib/routePlanner/tripCookie';
import { updateTripUrl } from 'lib/routePlanner/updateTripUrl';
import { MapControllers } from 'models/MapControllers';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { Waypoint } from 'recoil/routePlanner';
import { truckMapConfig } from 'truckMapConfig';

import {
  DefaultLocationWaypoint,
  defaultWaypoints,
  EmptyWaypoint,
  routePlannerAtom
} from '../../recoil/routePlanner/routePlannerAtom';

const isTenstreet = truckMapConfig.isTenstreet;
const MAX_EXPAND_WAYPOINTS_DISPATCH = 7;
const MAX_EXPAND_WAYPOINTS = 5;
export const MAX_TOTAL_WAYPOINTS = 12;

export type WaypointHelperCallbackTypes = {
  addWaypoint: (waypoint?: Waypoint, index?: number) => { id: string; index: number };
  removeWaypoint: (waypoint?: Waypoint) => void;
  sortWaypoints: (activeId: string, overId: string) => void;
  setWaypoint: (index: number, waypoint: Waypoint) => void;
  setEmptyWaypoint: (index: number) => void;
  onWaypointChange: () => void;
};

export type WaypointHelperTypes = {
  resetWaypoints: () => void;
  hasExpandedWaypoints: boolean;
  hasExpandedWaypointsMaximum: boolean;
  isAddingWaypoint: boolean;
  isEditingOriginDestination: boolean;
  toggleAddWaypoint: (status: boolean) => void;
  toggleEditWaypoints: (status: boolean) => void;
  toggleEditOriginDestination: (status: boolean) => void;
  swapOriginDestinationWaypoints: () => void;
  hasFirstLastWaypointValues: boolean;
  isDuplicateWaypoint: (index: number, item: SearchResult) => boolean;
  isCurrentLocationWaypoint?: (index: number) => boolean;
};

export type WaypointActionReturnType = [
  waypoints: Waypoint[],
  actions: WaypointHelperCallbackTypes,
  toggleEdit?: WaypointHelperTypes
];

export enum PingEventGroups {
  ROUTE_PLANNER = 'RoutePlanner'
}

export const useWaypoints = (waypointsMapController?: MapControllers): WaypointActionReturnType => {
  const mapController = useMapControllerContext();
  const [state, setState] = useRecoilState(
    routePlannerAtom(waypointsMapController || mapController)
  );
  const getMapInstance = useGetMapInstance(mapController);
  const { removeMarkersHandler } = useMapGLTripMarkerHandlers(mapController);
  const { resetDispatchTrip, isDispatch } = useDispatchResetTrip(mapController);

  const onWaypointChange = useCallback(() => {
    const mapInstance = getMapInstance();
    resetMapRoute(mapInstance);
    removeMarkersHandler();
    !isDispatch && updateTripUrl();
  }, [getMapInstance, resetMapRoute, removeMarkersHandler]);

  const hasExpandedWaypoints = useMemo(
    () => state.hasExpandedWaypoints,
    [state.hasExpandedWaypoints]
  );

  const toggleEditWaypoints = useCallback(
    (status: boolean) => {
      setState(
        produce((draft) => {
          draft.hasExpandedWaypoints = status;
        })
      );
    },
    [state.hasExpandedWaypoints]
  );

  const isAddingWaypoint = useMemo(() => state.isAddingWaypoint, [state.isAddingWaypoint]);

  const hasExpandedWaypointsMaximum = useMemo(
    () =>
      state.waypoints.length >= (isDispatch ? MAX_EXPAND_WAYPOINTS_DISPATCH : MAX_EXPAND_WAYPOINTS),
    [state.waypoints, isDispatch]
  );

  const shouldCollapseWaypoints = useMemo(
    () =>
      hasExpandedWaypoints &&
      state.waypoints.length ===
        (isDispatch ? MAX_EXPAND_WAYPOINTS_DISPATCH - 1 : MAX_EXPAND_WAYPOINTS_DISPATCH - 1),
    [hasExpandedWaypoints, state.waypoints, isDispatch]
  );

  const toggleAddWaypoint = useCallback(
    (status: boolean) => {
      setState(
        produce((draft) => {
          draft.isAddingWaypoint = status;
        })
      );
    },
    [state.isAddingWaypoint]
  );

  const isEditingOriginDestination = useMemo(
    () => state.isEditingOriginDestination,
    [state.isEditingOriginDestination]
  );

  const toggleEditOriginDestination = useCallback(
    (status: boolean) => {
      setState(
        produce((draft) => {
          draft.isEditingOriginDestination = status;
        })
      );
    },
    [state.isEditingOriginDestination]
  );

  useEffect(() => {
    !isTenstreet && watchLocalStorageData(setState);
  }, []);

  const resetWaypoints = useRecoilCallback(
    ({ set }) =>
      () => {
        pingEvent('RESET_WAYPOINTS', {
          previousTripId: state.tripId,
          component: PingEventGroups.ROUTE_PLANNER
        });

        const map = getMapInstance();
        resetDirectionPoint(map);

        set(
          routePlannerAtom(waypointsMapController),
          produce((draft) => {
            draft.tripId = undefined;
            draft.currentTrip = undefined;
            draft.waypoints = defaultWaypoints;
          })
        );

        !isTenstreet && removeTripCookie();
        isTenstreet && resetDispatchTrip();

        onWaypointChange();
      },
    [getMapInstance, state, resetDispatchTrip]
  );

  const addWaypoint = useCallback(
    (waypoint?: Waypoint, indexValue?: number) => {
      const waypointId = nanoid();
      let index = -1;

      setState(
        produce((draft) => {
          const newWaypoint = waypoint || {
            id: waypointId,
            name: ''
          };

          if (!isDispatch) draft.tripId = undefined;

          if (indexValue === undefined) {
            // if the last waypoint is the default one, replace it
            // default waypoints are defined when the atom is created
            if (draft.waypoints[draft.waypoints.length - 1].default) {
              draft.waypoints[draft.waypoints.length - 1] = newWaypoint;
            } else {
              const newWaypoints = [...draft.waypoints, newWaypoint];
              index = newWaypoints.findIndex((w) => w.id === newWaypoint.id);
              draft.waypoints = newWaypoints;
            }

            if (shouldCollapseWaypoints) draft.hasExpandedWaypoints = false;
          } else {
            draft.waypoints.splice(indexValue, 0, waypoint);
          }
        })
      );

      return {
        id: waypoint?.id || waypointId,
        index: indexValue ?? index
      };
    },
    [state.waypoints]
  );

  const removeWaypoint = useCallback((waypoint?: Waypoint) => {
    let newState = [];

    onWaypointChange();

    setState(
      produce((draft) => {
        if (!isDispatch) {
          draft.tripId = undefined;
          draft.currentTrip = undefined;
        }

        draft.waypoints = draft.waypoints.filter((w) => waypoint.id !== w.id);
        newState = draft.waypoints;
      })
    );

    return newState;
  }, []);

  const setWaypoint = useCallback((index: number, waypoint: Waypoint) => {
    setState(
      produce((draft) => {
        if (!isDispatch) draft.tripId = undefined;
        draft.waypoints[index] = { ...waypoint, id: waypoint.id || nanoid() };
      })
    );
  }, []);

  const setEmptyWaypoint = useCallback(
    (index: number) => {
      onWaypointChange();

      return setWaypoint(index, {
        name: '',
        id: nanoid()
      } as EmptyWaypoint);
    },
    [setWaypoint, onWaypointChange]
  );

  const sortWaypoints = useCallback((activeId: string, overId: string) => {
    return setState(
      produce((draft) => {
        if (!isDispatch) draft.tripId = undefined;

        const oldIndex = draft.waypoints.findIndex((item) => item.id === activeId);
        const newIndex = draft.waypoints.findIndex((item) => item.id === overId);

        draft.waypoints = arrayMove(draft.waypoints, oldIndex, newIndex);
      })
    );
  }, []);

  const swapOriginDestinationWaypoints = useCallback(() => {
    return setState(
      produce((draft) => {
        if (!isDispatch) draft.tripId = undefined;
        const lastIndex = draft.waypoints.length - 1;

        draft.waypoints = [draft.waypoints[lastIndex], draft.waypoints[0]];
      })
    );
  }, []);

  const isPlaceWaypoint = useCallback((waypoint) => {
    return waypoint && waypoint.objectType;
  }, []);

  const isDuplicateWaypoint = useCallback(
    (index: number, item: SearchResult) => {
      const previousWaypoint = state.waypoints[index - 1];
      return (previousWaypoint as IPlace)?.objectKey === (item as IPlace)?.objectKey;
    },
    [state.waypoints]
  );

  const isCurrentLocationWaypoint = useCallback(
    (index: number) => {
      return (state.waypoints[index] as DefaultLocationWaypoint)?.objectType === 'currentLocation';
    },
    [state.waypoints]
  );

  const hasFirstLastWaypointValues = useMemo(
    () => isPlaceWaypoint(state.waypoints[0]) && isPlaceWaypoint(state.waypoints[1]),
    [state.waypoints, isPlaceWaypoint]
  );

  return [
    state.waypoints.filter((w) => w.id),
    { addWaypoint, sortWaypoints, setWaypoint, setEmptyWaypoint, removeWaypoint, onWaypointChange },
    {
      hasExpandedWaypoints,
      hasExpandedWaypointsMaximum,
      toggleEditWaypoints,
      resetWaypoints,
      isAddingWaypoint,
      toggleAddWaypoint,
      isEditingOriginDestination,
      toggleEditOriginDestination,
      swapOriginDestinationWaypoints,
      hasFirstLastWaypointValues,
      isDuplicateWaypoint,
      isCurrentLocationWaypoint
    }
  ];
};
