import { ITripInstruction } from '@truckmap/common';
import { activeDirectionsAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { FeatureCollection, Geometry } from 'geojson';
import { getMapInstance, TruckMapGL, useMapGLController } from 'hooks/useMap/useMapGL/MapGLContext';
import { AnySourceData, GeoJSONSource } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

const SOURCE_NAME = 'active_direction';

export function resetDirectionPoint(mapInstance: TruckMapGL) {
  (mapInstance?.getSource(SOURCE_NAME) as GeoJSONSource)?.setData({
    type: 'FeatureCollection',
    features: []
  });
}

export const useMapDirectionFocus = (controllerId: MapControllers) => {
  const mapInstance = useMapGLController(controllerId);
  const activeDirectionValue = useRecoilValue(activeDirectionsAtom(controllerId));

  const coordinates = activeDirectionValue && [
    activeDirectionValue.longitude,
    activeDirectionValue.latitude
  ];

  const flyToDirection = useCallback(
    () =>
      coordinates &&
      mapInstance?.flyTo({
        center: coordinates as [number, number],
        zoom: 15
      }),
    [coordinates, mapInstance]
  );

  const handleDirectionValue = useCallback(
    (activeDirectionValue: ITripInstruction) => {
      const map = (mapInstance as TruckMapGL) || getMapInstance(controllerId);

      const source = map?.getSource(SOURCE_NAME) as GeoJSONSource;

      if (map) {
        if (!source) {
          map.addSource(SOURCE_NAME, {
            type: 'geojson',
            data: {}
          } as AnySourceData);
        }

        const data: FeatureCollection<Geometry, { [name: string]: unknown }> = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates
              },
              properties: {
                isActive: Boolean(activeDirectionValue)
              }
            }
          ]
        };

        (map.getSource(SOURCE_NAME) as GeoJSONSource).setData(data);
      }
    },
    [coordinates, controllerId, mapInstance]
  );

  const resetDirection = useCallback(() => {
    if (mapInstance?.getSource(SOURCE_NAME)) {
      resetDirectionPoint(mapInstance as TruckMapGL);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (!activeDirectionValue) return resetDirection();

    flyToDirection();
    handleDirectionValue(activeDirectionValue);
  }, [activeDirectionValue]);
};
