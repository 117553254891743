import { useWaypoints } from 'hooks/useRoutePlanner/useWaypoints';
import { MapControllers } from 'models/MapControllers';
import { createContext, useContext } from 'react';

type WaypointsProvider = {
  children: React.ReactNode;
  recoilAtomFamily: MapControllers;
};

const WaypointsValueContext = createContext([]);
const WaypointsActionsContext = createContext([]);

const WaypointsProvider = ({ children }) => {
  const [waypoints, actions, helpers] = useWaypoints();

  return (
    <WaypointsValueContext.Provider value={waypoints}>
      <WaypointsActionsContext.Provider value={[actions, helpers]}>
        {children}
      </WaypointsActionsContext.Provider>
    </WaypointsValueContext.Provider>
  );
};

const useWaypointsValueContext = () => {
  const context = useContext(WaypointsValueContext);
  if (!context) {
    throw new Error('useWaypointsValue must be used within a WaypointsProvider');
  }
  return context;
};

const useWaypointsActionsContext = () => {
  const context = useContext(WaypointsActionsContext);
  if (!context) {
    throw new Error('useWaypointsActions must be used within a WaypointsProvider');
  }
  return context;
};

export { WaypointsProvider, useWaypointsValueContext, useWaypointsActionsContext };
